.highend-addon-image {
    position: relative;
    top: .2rem;
    border-radius: .3rem;
    height: 45vh;
    width: auto; 
}

#addon-one h2 {
    font-size: 2rem;
}

#addon-two h2 {
    font-size: 2rem;
}

#addon-one {
    background-image: url("../Images/appliances/black-squares.webp");
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 130vh;
    width: 100vw;
    padding-top: 5rem;
}

.in-left {
    transition: all 1s;
    position: relative;
    left: -15rem
}

.in-right {
    transition: all 1s;
    position: relative;
    right: -15rem
}

.animate-left {
    transition: all 1s;
    position: relative;
    left: 0;
}

.animate-right {
    transition: all 1s;
    position: relative;
    right: 0;
}

.highend-addon-info {
    display: flex;
    align-items: center;
    text-align: center;
    width: 35%;
}

.first-half, .second-half {
    display: flex;
    justify-content: space-around;
    color: white;
}

.image-frame {
    padding: 1rem;
    background-color: rgb(0, 0, 0, 0.4);
    box-shadow: 2px 2px 5px rgb(0, 0, 0);
    border-radius: .4rem;
    margin: 3rem;
}

#addon-two {
    background-image: url("../Images/appliances/black-squares-upside.webp");
    background-size: cover;
    margin-top: -1px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 130vh;
    width: 100vw;
    margin-bottom: -5rem;
    padding-bottom: 5rem;
}

#part-two {
    display: flex;
    justify-content: flex-end;
}

.desktop-hidden {
    display: none;
}

@media only Screen and (max-width: 48em) {
    #addon-one .first-half{
        padding-top: 35vh;
    }

    #addon-one {
        height: auto;
    }
    
    #addon-two {
        margin-bottom: -3rem;
        height: auto;
    }

    .highend-addon-info {
        font-size: .9rem;
    }
    
    .image-frame {
        padding: .8rem;
        background-color: rgb(0, 0, 0, 0.4);
        box-shadow: 2px 2px 5px rgb(0, 0, 0);
        border-radius: .4rem;
        margin: 3rem 0 3rem 0 ;
    }
    
    .highend-addon-image {
        width: 84vw;
        height: auto;
    }

    .first-half, .second-half {
        flex-direction: column;
    }

    .animate-left {
        position: relative;
        left: 5%;
        width: 90vw;
    }
    
    .animate-right {
        position: relative;
        right: 5%;
        width: 90vw;
    }

    .second-half .animate-right {
        position: relative;
        right: -5%;
        width: 90vw;
    }

    .first-half .animate-right {
        position: relative;
        right: -5%;
        width: 90vw;
    }

    .in-left {
        position: relative;
        left: -70vw;
        width: 90vw;
    }

    .in-right {
        position: relative;
        right: -70vw;
        width: 90vw;
    }

    .desktop-hidden {
        display:block;
    }

    .mobile-hidden {
        display: none;
    }
}