:root{
    --orange:#FFC436;
    --deep-blue:#002a61;
    --light-blue:#009dff;
    --green:#4A8271;
    --peach:#fffefb;
    --light-grey:#cecece;
}

* {
    margin:0;
    padding:0;
    box-sizing:border-box;
    font-family: sans-serif;
    line-height: 1.5;
}
html {
    scroll-behavior:smooth;
    overflow-x: hidden;
}
body {
    overflow-x: hidden;}
a {
    text-decoration: none;
    font-weight: 600;
    color: var(--deep-blue);
}

a:hover {
    text-decoration: none;
    font-weight: 600;
    color: var(--light-blue);
}
