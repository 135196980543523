#footer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: var(--deep-blue);
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  white-space: nowrap;
  position: relative;
  z-index: 9;
}

#footer-first-section {
  margin-top: -3vw;
  padding: 30px 70px;
  display: flex;
  flex-direction: row;
  max-width: 90vw;
  min-width: 75vw;
  justify-content: space-between;
  align-items: center;
  background-color: var(--light-grey);
  column-gap: 1rem;
  border-radius: 4px;
}

#footer-first-section img {
    width: 4vw;
    height: 4vw;
    align-self: center;
}

.section-card {
  display: flex;
  column-gap: 1rem;
}

.section-text {
    display: flex;
    flex-direction: column;
    align-self: center;
}

#footer-first-section p {
    font-size:16px;
}

#footer-first-section a {
    color:navy;
    font-weight: 600;
    font-size:calc(1.2rem + 0.3vw);
}

#footer-first-section a:hover {
    color:var(--light-blue) 
}
  
#footer-second-section {
  display: flex;
  flex-direction: row;
  color: white;
  margin: 3rem;
  height: auto;
  justify-content: space-between;
  font-size: 2vh;
  column-gap: 8rem;
}
  
#company-logo {
  width: 16em;
}

#footer-second-section p{
  font-size:12px;
  margin-top: 1rem;
  width: 15rem;
}

#footer-second-section iframe{
  height: 100%;
  width: 100%;
  transition: all 0.3s;
}

#footer-second-section iframe :hover{
    transform: scale(1.05);
}
 
#footer-bottom-line {
  display: flex;
  color: white;
  font-size: 1rem;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1.2rem;
  width: auto;
}

#footer-services { 
  font-size: 1.2vw;
  row-gap: 0.6rem;
  align-items: start;
}

#footer-services a{ 
  color: white;
  font-weight: 600;
}

#footer-services a::after{
  content:"";
  display: block;
  height: 2px;
  width: 3px;
  background: transparent;
  transition: width 0.8s;
}

#footer-services a:hover::after{
  width:100%;
  background: var(--orange);
}

#footer-services a:hover{ 
  color: var(--orange);
  font-weight: 600;
}

#icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 1rem;
}

.icon {
  height: 3.5vw;
  width: 3.5vw;
  border-radius: 2vh;
  object-fit: contain;
}

@media only Screen and (max-width: 48em) {
  #footer {
    margin-top: 2rem;
  }

  #footer-first-section {
    flex-direction: column;
    width: 100vw;
    max-width: 100vw;
    padding: 1.5rem 0px;
    border-radius: 0;
    align-items: flex-start;
    padding-left: 1rem;
  } 
  
  #footer-first-section img {
    height: 2.5rem;
    width: 2.5rem;
    
  }

  #footer-second-section {
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;
  }
  
  #icons {
    align-items: center;
  }
  
  #icons img {
    width: auto;
    height: 18vw;
  }
  
  #footer-second-section iframe {
    width: 90vw;
  }
  
  #footer-services {
    font-size: 1.7rem;
    align-items: center;
    row-gap: 1.3rem;
    text-decoration: underline;
  }
}