#hours-container {
    display: flex;
    column-gap: 3rem;
    margin-bottom: 2rem;
    
}

.hours-box {
    display:flex;
    background-color: white;
    border: 3px solid var(--deep-blue);
    border-radius: 12px;
    padding: 2rem 4rem;
    flex-direction: column;
    font-size: 1.2rem;
    box-shadow: 2px 2px 6px rgb(110, 110, 110);
    width: 30vw;
  }

.hours-table {
    width: 100%;
}

.hours-table-header {
    text-align: center;
}

@media only Screen and (max-width: 48em){
    #hours-container {
        flex-direction: column;
        row-gap: 3rem;
    }

    .hours-box {
        width: 90vw;
        padding: 2rem 1rem;
    }
}