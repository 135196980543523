.appliance-container section {
    margin-top: 2rem;
}

.appliance-container p {
    margin-top: 0.8rem;
}


.appliance-container {
    display: flex;
    column-gap: 2rem;
    padding: 3rem;
    padding-left: 10%;
    column-gap: 5rem;
}

.appliance-container table{
    justify-self: center;

}

.appliance-container h1{
    justify-self: center;
    margin-bottom: 1rem;

}

.photos-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 22%;
    margin: 10vh 0 0 0;
    row-gap: 2.5rem;
}

.photos-container img {
    width: 15vw;
    box-shadow: 2px 2px 12px rgb(110, 110, 110);
    border-radius: 1rem;
}

.photos-container-mobile {
    display: none;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.3rem;
    row-gap: 1rem;
    width: 42%;
    
}

.text-container p{
    font-size: 1.3rem;
    text-indent: 30px;
}

.page-container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}


@media only Screen and (max-width: 48em){
    .appliance-container {
        display: flex;
        flex-direction: column;
        width: 100vw;
    }

    .text-container {
        width: 100%;  
    }

    .photos-container {
        display: none;
    }

    .photos-container-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 10vh 0 0 0;
        row-gap: 3.5rem;
        
    }

    .photos-container-mobile img {
        width: 80vw;
        border-radius: 2rem;
        box-shadow: 2px 2px 12px rgb(110, 110, 110);
    }
}